import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Hero2 from '../assets/banner-image1.png'

import "animate.css";
import applelogo from '../assets/applelogo.png'
import googlelogo from '../assets/googlelogo.png'
import Navbar from '../component/Navbar/navbar'
import arrow from '../assets/downloadarrow.png'
import phone from '../assets/phoneimg.png'
import note from '../assets/downloadnote.png'
import superline from '../assets/superline-logo4.png'

import { AnimationOnScroll } from "react-animation-on-scroll";
import EmiCalculator2 from '../component/EMIcalculator2'
import LoanSteps from '../component/LoanSteps'
import Caraousel from '../component/Caraousel'
import Footer from '../component/Footer/Footer';
function Homepage() {

    // const [activeStep, setActiveStep] = useState(1);

    // useEffect(() => {
    //     const textElements = document.querySelectorAll('.text1, .text2, .text3, .text4');

    //     const handleScroll = () => {
    //         textElements.forEach((textElement, index) => {
    //             const rect = textElement.getBoundingClientRect();
    //             if (rect.top >= 0 && rect.bottom <= (window.innerHeight * 1.3)) {
    //                 setActiveStep(index + 1);
    //                 console.log(activeStep);
    //             }
    //         });
    //     };



    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [activeStep]);




    let response = {
        0: {
            items: 1,
            dots: false,
            nav: true
        },
        600: {
            items: 2,
            dots: false,
            nav: true
        },
        1000: {
            items: 3,
            dots: true,
            nav: false,
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
        }
    };






    return (
        <>
            <Navbar />
            <div className="banner-area circle-shape auto-height text-left text-light mb-5" >

                <div className="container">
                    <div className="content-box ">
                        <div className="row align-center">
                            <div className="col col-lg-6 info">
                                <div className="headingtext1">
                                    <h2><strong>Welcome to Borrow Buddy</strong><br /> Your Financial Buddy</h2>

                                    <p>Borrow Buddy: The reliable choice for fast, hassle-free microloans, acting as a friendly partner in unexpected financial situations.</p>
                                    <a href="https://play.google.com/store/search?q=borrowbuddy&c=apps&hl=en" target="_blank" className="btn btn-lg mt-4 border">Apply Now</a>
                                </div>

                            </div>
                            <div className=" col-lg-6 ">
                                <div className="thumb-outer circle-shape2 ">

                                    <div className="thumb-innner mb-5  circle-shape3">
                                        <div className="shapes">
                                            <img className='imgss11 ' src={Hero2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-features-area mt-5 mb-5" id="AU">
                <div className="container">
                    <div className="row text-center">
                        <div className="col">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <h4>About Us</h4>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col px-5">
                            <h6>
                                Our app's cutting-edge features ensure a seamless borrowing experience by giving users quick and simple access to financial options. Your financial transactions are made simple and secure with BorrowBuddy, which makes them available on the web and on mobile devices. With our firm, take advantage of a new level of accessibility and financial simplicity.

                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whatweoffer" id="WWO">
                <Caraousel />
            </div>

            <LoanSteps />
            <EmiCalculator2 />

            <div className="lendingPartner mt-5 pb-5">
                <div className="container ">
                    <div className="row text-center">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h2 style={{ fontSize: '35px' }}>Our Lending Partners</h2>
                        </AnimationOnScroll>
                    </div>

                    <div className="row mt-5 pt-2 pb-5 d-flex justify-content-center text-center">
                        <div className="col-sm-6 col-xs-12">
                            <div className="partner text-center">
                                <img src={superline} alt="superline logo" width={'40%'} /> <br />
                                <a href="https://superline.in/" target="_blank">SUPERLINE FININVEST PVT. LTD</a>
                                <h5> An Indian Non-Banking Finance Company which deals in SME Lending and Digital Lending</h5>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div className="download left-side right-phone" id="GetApp">
                <div className="container right-side" >

                    <div className="row justify-content-between text-center">
                        <div className="col-sm-2 col-xs-1  offset-md-1" id='downloadcol1'>
                            <AnimationOnScroll animateIn="animate__fadeInLeftBig" duration={'0.8'}>
                                {" "}
                                <img src={arrow} alt="" />
                            </AnimationOnScroll>
                        </div>
                        <div className="col-sm-2 col-xs-1" id='downloadcol1'>
                            <AnimationOnScroll animateIn="animate__flipInX" duration={'2'}>
                                {/* <AnimationOnScroll animateIn="animate__fadeInRightBig"> */}
                                {" "}
                                <img src={note} alt="" />
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col">
                            <h2>Download Our App</h2>
                            <h6>Enjoy the Borrowbuddy experience seamlessly on your Android or iOS phones.</h6>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center text-center mt-4">
                        {/* <div className="col-md-3 col-xs-12">
                            <a href="" style={{ width: '80%' }}><img src={applelogo} alt="" /></a>
                        </div> */}
                        <div className="col-md-3 col-xs-12">
                            <a href="https://play.google.com/store/search?q=borrowbuddy&c=apps&hl=en" target="_blank" style={{ width: '80%' }}><img src={googlelogo} alt="" /></a>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-5 col-xs-12">

                        </div>

                        <div className="col-lg-5 col-xs-12" id=''>
                            <AnimationOnScroll animateIn="animated-pop-in" duration="4s">
                                {" "}

                                <img src={phone} width={"55%"} alt="" style={{ float: 'right' }} />
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>




            <div className="FAQ mt-5 mb-5" id='FAQ'>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <h2 className='faqheading'>Frequently Asked Questions</h2>
                            </AnimationOnScroll>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div class="accordion faqaccordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
                                            Is Borrow Buddy safe?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Absolutely. We use top-notch security to protect your information, and our clear terms ensure a secure borrowing experience.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3" >
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How quickly can I get approved?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            With our fast assessment, you can get pre-approved within seconds of applying.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What documents do I need to provide?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            The required documents may vary, but they could include proof of identity, income verification, and bank statements. Our app will guide you through this process.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" >
                                            Can I pay off my loan early?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, you can repay your loan ahead of time without any extra charges. We encourage responsible borrowing.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            How can I contact Borrow Buddy for support?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            For any assistance or inquiries, you can reach out to our dedicated customer support team, whose members are ready to assist you at every step of your Borrow Buddy journey
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}

export default Homepage


