import React from 'react'
import logoWhite from '../../assets/Borrowbuddy-logo2.png'
import callicon from '../../assets/callicon-footer.png'
import { Link } from 'react-router-dom'
import locationicon from '../../assets/locationicon-footer.png'
import mailicon from '../../assets/mailicon-footer.png'
import callimg1 from '../../assets/call_img.png'
import mailimg1 from '../../assets/mail_img.png'
import locationimg1 from '../../assets/location_img.png'
import appleicon from '../../assets/appleicon.png'
import googleplayicon from '../../assets/googleplayicon.png'


export default function Footer() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    function scrollToSection(sectionId) {

        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <>
            <footer className="text-light" id="contact" style={{ backgroundColor: '#175590' }}>
                <div className="svg-shape">

                </div>
                <div className="container pt-3">
                    <div className="f-items default-padding pt-5">
                        <div className="row">
                            <div className="col">
                                <Link className="navbar-brand me-5" href="#" to={'/'} onClick={() => scrollToTop()}>
                                    <img src={logoWhite} alt="Logo" className='logofooter' width={'20%'} />
                                </Link>

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-3 col-md-6 item">
                                <div className="f-item about">

                                    <div className="address">
                                        <h4 className="widget-title" style={{ color: 'white' }}>Contact Us</h4>
                                        <ul>

                                            <li>

                                                {/* <div className="icon me-3">
                                                    <a href="" >
                                                        <img src={callicon} alt="" style={{ position: 'relative', top: '5px', left: '10%' }} />
                                                    </a>
                                                </div> */}
                                                <div className='d-flex'>
                                                    {/* <img src={callimg1} className="me-3" alt="" width={'37.8px'} /> */}
                                                    {/* <img src={callimg1} className="me-3" alt="" width={'17%'} /> */}
                                                    {/* <p className='mt-1 text-white'>+91- 9810709494</p> */}
                                                </div>

                                            </li>
                                            <li>
                                                {/* <div className="icon me-3">
                                                    <a href="" >
                                                        <img src={mailicon} alt="" style={{ position: 'relative', top: '10px', left: '10%' }} />
                                                    </a>
                                                </div>
                                                <div className="info">

                                                    <a href="mailto:connect@borrowbuddy.co.in" className='ahref'>connect@borrowbuddy.co.in</a>
                                                </div> */}
                                                <div className='d-flex'>
                                                    <img src={mailimg1} className="me-3" alt="" width={'37.8px'} />
                                                    <p className='mt-1'> <a href="mailto:support@borrowbuddy.co.in" className='ahref'>support@borrowbuddy.co.in</a></p>
                                                </div>

                                            </li>
                                            <li>
                                                {/* <div className="icon me-3">
                                                    <a href="" >
                                                        <img src={locationicon} alt="" style={{ position: 'relative', top: '10%', left: '17%' }} />
                                                    </a>
                                                </div>
                                                <div className="info">
                                                    <p>BORROWBUDDY E-SERVICE PRIVATE LIMITED<br /> M-3 Pratap Bhawan,<br /> 5 Bahadur Shah Zafar Marg I.P.Estate ,New Delhi <br /> Central Delhi- 110002,India</p>
                                                    <p>CIN:U62099DL2023PTC415259</p>

                                                </div> */}
                                                <div className='d-flex'>
                                                    <img src={locationimg1} className="me-3" alt="" width={'37.8px'} height={'38.6px'} />
                                                    <p className='mt-1 text-white'>BORROWBUDDY E-SERVICE PRIVATE LIMITED<br /> M-3 Pratap Bhawan,<br /> 5 Bahadur Shah Zafar Marg I.P.Estate ,New Delhi <br /> Central Delhi- 110002,India <br />CIN:U62099DL2023PTC415259</p>
                                                    {/* <p>CIN:U62099DL2023PTC415259</p> */}
                                                </div>

                                            </li>



                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 item">
                                <div className="f-item link">
                                    <h4 className="widget-title mb-4" style={{ color: 'white' }}>Company</h4>
                                    <ul>
                                        <li>
                                            <Link to={{
                                                pathname: "/",
                                            }} onClick={() => scrollToTop()}>Home</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" href="#" onClick={() => scrollToSection('AU')}>About us</Link>

                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" href="#" onClick={() => scrollToSection('ourProduct')}>Our Product</Link>
                                            {/* <Link className="nav-link ps-0" href="#" onClick={() => scrollToSection('WWO')}>What We Offer</Link> */}

                                        </li>
                                        {/* <li>
                                        <Link className="nav-link ps-0" href="#" >Why Apply</Link>
                                            
                                        </li> */}
                                        <li>
                                            <Link className="nav-link ps-0" href="#" onClick={() => scrollToSection('FAQ')}>FAQs</Link>

                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-6 item">
                                <div className="f-item link">
                                    <h4 className="widget-title mb-4" style={{ color: 'white' }}>Useful Links</h4>
                                    <ul>

                                        <li>
                                            <Link to={{
                                                pathname: "/home/PrivacyPolicy"
                                            }} className="nav-link ps-0" onClick={() => scrollToTop()}>Privacy policy</Link>

                                        </li>
                                        <li>
                                            <Link to={{
                                                pathname: "/home/TermsCondition"
                                            }} className="nav-link ps-0" onClick={() => scrollToTop()}>T&C</Link>

                                        </li>
                                        <li>
                                            <Link to={{
                                                pathname: "/home/Support"
                                            }} className="nav-link ps-0" onClick={() => scrollToTop()}>Support</Link>

                                        </li>
                                        <li>
                                            <Link to={{
                                                pathname: "/home/RefundCancellation"
                                            }} className="nav-link ps-0" onClick={() => scrollToTop()}>Refund & Cancellation Policy</Link>

                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" href="#" onClick={() => scrollToSection('EC')}>EMI Calculator</Link>

                                        </li>
                                        {/* <li>
                                            <Link className="nav-link ps-0" href="#" onClick={() => scrollToSection('LS')}>Loan Steps</Link>

                                        </li> */}

                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 item">
                                <div className="App-download-links">
                                    <h4 className='mb-4'>Download The <span>Borrowbuddy</span> App</h4>
                                    {/* <a className="Store_links" href=''>
                                        <div className="imgss">
                                            <img src={appleicon} alt="Apple_Icon" width={'12%'} className="me-3" />
                                            <h5>Download On the <span>App Store</span></h5>
                                        </div>
                                    </a> */}
                                    <a className="Store_links" href="https://play.google.com/store/search?q=borrowbuddy&c=apps&hl=en" target="_blank">
                                        <div className="imgss">
                                        <img src={googleplayicon} alt="Google_play_icon" width={'10%'} className="me-3 my-1" />
                                            <h5>Download On the <span>Google Play</span></h5>
                                        </div>

                                    </a>
                                </div>
                            </div>
                            {/* <div className="col-xl-4 col-md-6 item">
                                <div className="itemcontact">
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput" />
                                                <label for="floatingInput" className='ps-0' >Name</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div class="form-floating mb-3">
                                                <input type="number" class="form-control" id="floatingInput" />
                                                <label for="floatingInput" className='ps-0' >Contact</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div class="form-floating mb-3">
                                                <input type="email" class="form-control" id="floatingInput" />
                                                <label for="floatingInput" className='ps-0' >Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput" />
                                                <label for="floatingInput" className='ps-0' >Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <Link className="btn btn-lg mt-3 border mb-3">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">

                            <div className="col text-right link text-center">
                                <ul>
                                    <li >
                                        <h5>© 2023 by BorrowBuddy E-Service Private Limited</h5>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
