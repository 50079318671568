import react,{ useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Hero2 from '../assets/banner-image1.png'

import "animate.css";
import applelogo from '../assets/applelogo.png'
import googlelogo from '../assets/googlelogo.png'
import Navbar from '../component/Navbar/navbar'
import arrow from '../assets/downloadarrow.png'
import phone from '../assets/phoneimg.png'
import note from '../assets/downloadnote.png'
import superline from '../assets/superline-logo4.png'
import productSectionimg1 from '../assets/productSectionimg1.png'
import productSectionimg2 from '../assets/productSectionimg2.png'

import { AnimationOnScroll } from "react-animation-on-scroll";
import EmiCalculator2 from '../component/EMIcalculator2'
import LoanSteps from '../component/LoanSteps'
import Caraousel from '../component/Caraousel'
import Footer from '../component/Footer/Footer';
import EmicalculatorNew from '../component/EmicalculatorNew';
import { Navigate } from 'react-router-dom';
export default function HomePageNew() {
    // const [activeStep, setActiveStep] = useState(1);

    // useEffect(() => {
    //     const textElements = document.querySelectorAll('.text1, .text2, .text3, .text4');

    //     const handleScroll = () => {
    //         textElements.forEach((textElement, index) => {
    //             const rect = textElement.getBoundingClientRect();
    //             if (rect.top >= 0 && rect.bottom <= (window.innerHeight * 1.3)) {
    //                 setActiveStep(index + 1);
    //                 console.log(activeStep);
    //             }
    //         });
    //     };



    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [activeStep]);

const [showMoreFaq,setshowMoreFaq]=useState(false)
const navigate = useNavigate();

    let response = {
        0: {
            items: 1,
            dots: false,
            nav: true
        },
        600: {
            items: 2,
            dots: false,
            nav: true
        },
        1000: {
            items: 3,
            dots: true,
            nav: false,
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
        }
    };






    return (
        <>
            <Navbar />
            <div className="banner-area circle-shape auto-height text-left text-light mb-5" >

                <div className="container">
                    <div className="content-box ">
                        <div className="row align-center">
                            <div className="col col-lg-6 info">
                                <div className="headingtext1">
                                    <h2><strong>Welcome to Borrow Buddy</strong><br /> Your Financial Buddy</h2>

                                    <p className='new-banner-para'>Need some quick and hassle-free cash help? Look no further, Borrow Buddy, Your Cash Buddy is here to lend a hand. We're all about fast microloans that are reliable and friendly for those unexpected money moments.</p>
                                    <p className='new-banner-para2'>Ready to get started? Hit that Apply Now button! Easy peasy.</p>
                                    <a href="https://play.google.com/store/search?q=borrowbuddy&c=apps&hl=en" target="_blank" className="btn btn-lg mt-4 border" style={{fontWeight:'400'}}>Apply Now</a>
                                </div>

                            </div>
                            <div className=" col-lg-6 ">
                                <div className="thumb-outer circle-shape2 ">

                                    <div className="thumb-innner mb-5  circle-shape3">
                                        <div className="shapes">
                                            <img className='imgss11 ' src={Hero2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-features-area mt-5 mb-5" id="AU">
                <div className="container">
                    <div className="row text-center">
                        <div className="col">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <h4 style={{ fontSize: '35px', color: '#3B3B3B' }}>About Us</h4>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col px-5">
                            <p>Hello from BorrowBuddy, your go-to helpers for all things money. We focus on personal loans and invoice financing to give you direct access to funds and power over your financial needs with honesty and simplicity.<br /><br />
                              
                                <span style={{fontWeight:'700',color:'#003F75'}}>Personal Loans:</span> Covering everything from sudden expenses to home upgrades or personal dreams. Low rates, easy repayments, plus a simple application process for stress-free borrowing.<br />
                                <span style={{fontWeight:'700',color:'#003F75'}}>Invoice Financing:</span> Use this option for leveraging unpaid bills as collateral for immediate funds. Ideal for managing costs, timely payments, and seizing growth chances.
                                With us at BorrowBuddy, it's all about being upfront, speedy, and putting you first every step of the way.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Products-section" id="ourProduct">
                <div className="container-fluid">
                    <div className="row text-center mb-5">
                        <div className="col">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <h4 style={{ fontSize: '35px', color: '#3B3B3B' }}>Our Products</h4>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-5 col-sm-12">
                            <div className="imgs">
                                <img src={productSectionimg1} alt="product-section-image" width={'95%'} height={'100%'} />
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-12">
                            <div className="top d-flex">
                                <div className="texts">
                                    <h3>Running a business and need some extra cash flow?</h3>
                                    {/* <h6>Borrow Buddy's <span>Invoice Financing</span> has got you covered for a smooth operation experience.</h6> */}
                                    <h6>Borrow Buddy's <span>invoice Financing</span> is your solution. Keep your operations smooth and worry-free with our efficient financing options.</h6>

                                    <h6 className='mt-3'><span style={{color:'#000'}}>Improved Cash Flow:</span> Access funds tied up in invoices to keep your business running smoothly.</h6>
                                    <h6><span style={{color:'#000'}}>Easy Application:</span> A hassle-free process with quick approval.</h6>
                                    <h6><span style={{color:'#000'}}>Reliable Support:</span> Dedicated customer service to assist you every step of the way.
</h6>
                                    <button className='ReadMoreBtn' onClick={()=>navigate('/home/Products/InvoiceFinancing')}>Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row SecondProduct">
                        <div className="col-lg-7 col-sm-12" id="col1">
                            <div className="top d-flex">
                                <div className="texts">
                                    <h3>Got something urgent came up or a surprise? Borrow Buddy is here to help.</h3>
                                    <h6>Our <span>short term loans</span> are designed for quick approval, ensuring you get the funds you need right when you need them. With flexible payment options that fit your budget, you can handle any surprise with ease and confidence.</h6>
                                    <h6 className='mt-3'><span style={{color:'#000'}}>Fast Approval:</span> Our streamlined process ensures quick access to funds.</h6>
                                    <h6><span style={{color:'#000'}}>Flexible Repayment:</span> Choose payment plans that suit your financial situation.</h6>
                                    <h6><span style={{color:'#000'}}>Transparent Terms:</span> No hidden fees or surprises, just straightforward loans.</h6>
                                    <button className='ReadMoreBtn' onClick={()=>navigate('/home/Products/shortTermLoans')}>Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12" id="col2">
                            <div className="imgs">
                                <img src={productSectionimg2} alt="product-section-image" width={'95%'} height={'100%'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="whatweoffer" id="WWO">
                <Caraousel />
            </div> */}

            {/* <LoanSteps /> */}
            {/* <EmiCalculator2 /> */}
            

            <div className="lendingPartner mt-5 pb-5">
                <div className="container ">
                    <div className="row text-center">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h2 style={{ fontSize: '35px' }}>Our Lending Partners</h2>
                        </AnimationOnScroll>
                    </div>

                    <div className="row mt-5 pt-2 pb-5 d-flex justify-content-center text-center">
                        <div className="col-sm-6 col-xs-12">
                            <div className="partner text-center">
                                <img src={superline} alt="superline logo" width={'40%'} /> <br />
                                <a href="https://superline.in/" target="_blank">SUPERLINE FININVEST PVT. LTD</a>
                                <h5> An Indian Non-Banking Finance Company which deals in SME Lending and Digital Lending</h5>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <EmicalculatorNew />

            <div className="FAQ mt-5 mb-5" id='FAQ'>
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="block-left">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <h2 className='faqheading'>Frequently Asked Questions</h2>
                            </AnimationOnScroll>
                        </div>
                        <div className="block-right" style={{alignSelf:'center'}}>
                            <h6 style={{fontSize:'18px',fontWeight:'400',cursor:'pointer',marginBottom:'0px'}} onClick={()=>setshowMoreFaq(!showMoreFaq)} className="btn">{showMoreFaq ? 'Read Less' : 'Read More'}</h6>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div class="accordion faqaccordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
                                        What's BorrowBuddy all about?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        You’re financial pals offering personal loans or invoice financing as per the situation demands!
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3" >
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How can I apply?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        breeze! Fill out an online form right here--<a href="https://play.google.com/store/search?q=borrowbuddy&c=apps&hl=en" target="_blank">Apply Now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        What kind of HELP can I get?

                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        We cover two bases : Personal loans + Invoice financing
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" >
                                        What’s required regarding eligibility?

                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        It differs depending on what loan works best; find more deets on our website.

                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Approval timing?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        It varies but expect speedy decisions within twenty-four hours usually.
                                        </div>
                                    </div>
                                </div>
                                {
                                    showMoreFaq ?
                                <>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                        Interested in rates?

                                        </button>
                                    </h2>
                                    <div id="collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Rates aren't fixed but competitive based on applicant’s profile and choice of loan type
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                        How does repayment work?
                                        </button>
                                    </h2>
                                    <div id="collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Bank debits make repayments painless through auto-debits set up during app submission
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                        How do missed payments affect credit scores?
                                        </button>
                                    </h2>
                                    <div id="collapseeight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Missed payments can negatively impact your credit score. Exercise caution to avoid this in the future.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                        What should I do in case of unforeseen circumstances?
                                        </button>
                                    </h2>
                                    <div id="collapsenine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Contact customer support immediately for assistance and guidance.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                                        How can I check my application status?
                                        </button>
                                    </h2>
                                    <div id="collapseten" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Use the dashboard's status retrieval feature available online to track your application.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                        Are there penalties for early repayments?
                                        </button>
                                    </h2>
                                    <div id="collapse11" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        BorrowBuddy allows penalty-free early repayments, offering flexible repayment options.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                        What should I do if my application is rejected?
                                        </button>
                                    </h2>
                                    <div id="collapse12" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        If your application is declined, a reason will be provided. Correct any issues and reapply later.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-3">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                        How secure is my information with BorrowBuddy?
                                        </button>
                                    </h2>
                                    <div id="collapse13" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Your information is securely stored and protected by robust security measures.
                                        </div>
                                    </div>
                                </div>
                                </>
                                 :''}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}
