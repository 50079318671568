import React, { useState, useEffect } from 'react'
import Navbar2 from '../component/Navbar/navbar2'
import Footer2 from '../component/Footer/Footer2'
import LS5 from '../assets/LS-5.png'
import LS6 from '../assets/LS-6.png'
import tickicon3 from '../assets/tickicon3.png'
import ProductPageimg1 from '../assets/ProductPageimg1.png'
import ProductPageimg2 from '../assets/ProductPageimg2.png'
import ProductPageimg3 from '../assets/ProductPageimg3.png'
import ProductPageimg4 from '../assets/ProductPageimg4.png'
import Caraousel from '../component/Caraousel'
import wwo1 from '../assets/wwo-1.png'
import wwo2 from '../assets/wwo-2.png'
import wwo3 from '../assets/wwo-3.png'
import wwo11 from '../assets/wwo1-1.png'
import wwo21 from '../assets/wwo2-2.png'
import wwo31 from '../assets/wwo3-1.png'
import { Link ,useLocation } from 'react-router-dom'
export default function Products() {
    const location = useLocation();
    const [pageData, setPageData] = useState(null);
    const [pageType, setPageType] = useState('');
    useEffect(() => {
        const url = location?.pathname?.split('/home/Products/');
        if (url[1] == 'InvoiceFinancing') {
            setPageData(InvoiceFinancingData)
            setPageType('InvoiceFinancing')
        } else if (url[1] == 'shortTermLoans') {
            setPageData(ShortTermLoans)
            setPageType('shortTermLoans')
        }
    }, [location])

    const InvoiceFinancingData = {
        banner: {
            bannerHeading: 'Ease Your Business with Invoice Financing',
            bannerData: 'Curious about what Invoice Financing really is? Let us at BorrowBuddy help you out! It\'s like magic - turn unpaid bills into usable cash by handing them over to us temporarily while we sort out the rest.',
            bannerImage: LS6,
        },
        KeyFeatures: [
            'Get up to 90% of invoice value upfront.',
            'Go from approval to funding in just 48 hours.',
            'Finance either single invoices or all your bills as needed.',
            'No added debt on your balance sheet!',
            'Know exactly what you\'re paying without hidden charges.',
            'Keep things discreet within our financing terms.'
        ],
        whatWeOffer: [
            {
                text: 'Boost cash flow instantly',
                subtext: 'Access cash tied up in invoices immediately to keep operations running smoothly.',
                ActiveImage: wwo11,
                Image: wwo1,
            },
            {
                text: 'Make smart moves towards growth goals',
                subtext: 'Invest in new opportunities and expand your business with additional capital.',
                ActiveImage: wwo2,
                Image: wwo21,
            },
            {
                text: 'Cover different expenses in your Business',
                subtext: 'Easily manage payroll, inventory purchases, and utility bills without financial stress.',
                ActiveImage: wwo31,
                Image: wwo3,
            },
            {
                text: 'Steer clear of bad debts',
                subtext: 'Maintain a healthy cash flow and avoid the risk of unpaid invoices affecting your business.',
                ActiveImage: wwo11,
                Image: wwo1,
            },
            {
                text: 'Focus on work while we handle the rest',
                subtext: 'Concentrate on growing your business while we take care of your financing needs.',
                ActiveImage: wwo2,
                Image: wwo21,
            },
        ],

        LoanProcess: [
            {
                Image: ProductPageimg1,
                text: 'Send those unpaid invoices our way - pick one or many based on your needs.',
            },
            {
                Image: ProductPageimg2,
                text: 'Receive up to 90% advance from us quickly upon approval.',
            },
            {
                Image: ProductPageimg3,
                text: 'Let your customer pay us directly, no fussing required on your part.',
            },
            {
                Image: ProductPageimg4,
                text: 'After gets paid up fully, we\'ll send you the leftover stash (minus our fee).',
            },
        ],
        LoanProcessNew: [
            {
                heading: 'Submit your unpaid invoices',
                text: 'Send us your outstanding invoices. Whether it\'s one invoice or many, we\'re here to help you access your funds quickly.'
            },
            {
                heading: 'Receive up to 90% advance',
                text: 'Upon approval, get up to 90% of the invoice value upfront. This immediate cash injection supports your business needs right away.'
            },
            {
                heading: 'Get paid effortlessly',
                text: 'We handle the collection directly from your customers. Once paid, we deduct a small fee and send you the remaining balance promptly.'
            },
        ],
        FAQ: [
            {
                heading: 'What is invoice financing?',
                text: 'Invoice financing lets businesses unlock cash tied up in unpaid invoices by selling them to Borrowbuddy at a discount.'
            },
            {
                heading: 'How does it work?',
                text: 'Borrowbuddy advances a portion of the invoice value (up to 90%) upfront. Once the customer pays, Borrowbuddy releases the remainder minus a fee.'
            },
            {
                heading: 'Who benefits from invoice financing?',
                text: 'Small to medium-sized businesses seeking quick access to cash flow without waiting for invoice payments.'
            },
            {
                heading: 'Advantages?',
                text: 'Immediate cash flow, operational stability, and easier qualification compared to traditional loans.'
            },
            {
                heading: 'Difference from factoring?',
                text: 'Invoice financing involves borrowing against invoices, while factoring involves selling invoices outright.'
            },
        ]
    };

    const ShortTermLoans = {
        banner: {
            bannerHeading: 'Why Go with Borrowbuddy Your Cash Buddy?',
            bannerData: 'Get a swift Yes without delays when applying here. Pick repayment schedules that match what works best for you. Expect no surprises with our crystal-clear service terms.',
            bannerImage: LS5,
        },
        KeyFeatures: [
            'Say goodbye to loan paperwork headaches – our system now makes it super easy! Yup – our app guides each step along the way like a pro!',
            'Transparency is key at BorrowBuddY - Our bare-faced pricing leaves nothing in the shadows! What you see is what you get. No confusion around these parts!',
            'Different needs mean varied loan solutions - And we totally get it! Big goal ahead or just need a small boost? Leave it to us!',
            'No matter if it’s a tiny sum or something more substantial – we\'ve got precise amounts between 5K to 2 lakhs ready when you are!',
            'Find peace in selecting loan tenures between sixty days and one year that tailors perfectly towards making your financial dreams come true!',
            'Tailor-made payment plans are yours for the taking - Where convenience meets affordable!'
        ],
        whatWeOffer: [
            {
                text: 'Hassle-Free Documentation',
                subtext: 'Applying for a loan is now easier than ever. We\'ve streamlined the document submission process, making it a breeze. Our user-friendly app will walk you through each step effortlessly.',
                ActiveImage: wwo11,
                Image: wwo1,
            },
            {
                text: 'Crystal-Clear Pricing',
                subtext: 'Transparency is our mantra at Borrow Buddy. Our pricing is straightforward, with no hidden fees. Before you borrow, you\'ll know exactly what you\'ll pay and when. Borrow with peace of mind, knowing our terms are as clear as day.',
                ActiveImage: wwo2,
                Image: wwo21,
            },
            {
                text: 'Customized Loan Solutions',
                subtext: 'We understand that everyone\'s financial needs are different. That\'s why Borrow Buddy offers a variety of loan options to match your specific requirements. Whether it\'s a small loan for unexpected expenses or a larger amount for significant goals, we\'ve got you covered.',
                ActiveImage: wwo31,
                Image: wwo3,
            },
            {
                text: 'Borrow What You Need',
                subtext: 'With Borrow Buddy, you can borrow any amount from 5,000 to 2 lakhs, depending on your needs and eligibility. Whether it\'s a modest sum or a more substantial financial boost, we\'re here to assist you.',
                ActiveImage: wwo11,
                Image: wwo1,
            },
            {
                text: 'Loan Terms That Suit You',
                subtext: 'Choose a loan duration that aligns with your financial goals. With tenures ranging from 60 days to 1 year, you have the flexibility to repay at your preferred pace.',
                ActiveImage: wwo2,
                Image: wwo21,
            },
            {
                text: 'Payment Tailored to You',
                subtext: 'At Borrow Buddy, we put you in control of your finances. Our flexible interest payment cycles allow you to create a repayment plan that aligns with your budget and lifestyle',
                ActiveImage: wwo31,
                Image: wwo3,
            },
        ],
        // whatWeOffer: [
        //     {
        //         text: 'Hassle-Free Documentation',
        //         subtext: 'Applying for a loan is now easier than ever. We\'ve streamlined the document submission process, making it a breeze. Our user-friendly app will walk you through each step effortlessly.',
        //         ActiveImage: wwo11,
        //         Image: wwo1,
        //     },
        //     {
        //         text: 'Crystal-Clear Pricing',
        //         subtext: 'Transparency is our mantra at Borrow Buddy. Our pricing is straightforward, with no hidden fees. Before you borrow, you\'ll know exactly what you\'ll pay and when. Borrow with peace of mind, knowing our terms are as clear as day.',
        //         ActiveImage: wwo2,
        //         Image: wwo21,
        //     },
        //     {
        //         text: 'Customized Loan Solutions',
        //         subtext: 'We understand that everyone\'s financial needs are different. That\'s why Borrow Buddy offers a variety of loan options to match your specific requirements. Whether it\'s a small loan for unexpected expenses or a larger amount for significant goals, we\'ve got you covered.',
        //         ActiveImage: wwo31,
        //         Image: wwo3,
        //     },
        //     {
        //         text: 'Borrow What You Need',
        //         subtext: 'With Borrow Buddy, you can borrow any amount from 5,000 to 2 lakhs, depending on your needs and eligibility. Whether it\'s a modest sum or a more substantial financial boost, we\'re here to assist you.',
        //         ActiveImage: wwo11,
        //         Image: wwo1,
        //     },
        //     {
        //         text: 'Loan Terms That Suit You',
        //         subtext: 'Choose a loan duration that aligns with your financial goals. With tenures ranging from 60 days to 1 year, you have the flexibility to repay at your preferred pace.',
        //         ActiveImage: wwo2,
        //         Image: wwo21,
        //     },
        //     {
        //         text: 'Payment Tailored to You',
        //         subtext: 'At Borrow Buddy, we put you in control of your finances. Our flexible interest payment cycles allow you to create a repayment plan that aligns with your budget and lifestyle',
        //         ActiveImage: wwo31,
        //         Image: wwo3,
        //     },
        // ],

        LoanProcess: [
            {
                Image: ProductPageimg1,
                text: 'Start by applying online via our app.',
            },
            {
                Image: ProductPageimg2,
                text: 'We\'ll do a quick review once submitted.',
            },
            {
                Image: ProductPageimg3,
                text: 'Hello money! Funds will be sent over in no time.',
            },
        ],
        LoanProcessNew: [
            {
                heading: 'Easy Online Application',
                text: 'Apply conveniently online, filling out a straightforward form tailored for quick submission.'
            },
            {
                heading: 'Rapid Approval Process',
                text: 'Experience a swift approval process facilitated by our digital platform, ensuring minimal waiting time.'
            },
            {
                heading: 'Direct Funds Transfer',
                text: 'Upon approval, funds are promptly transferred directly to your designated bank account, enabling immediate use for your financial needs.'
            },
        ],
        FAQ: [
            {
                heading: 'What are short term loans?',
                text: 'Small-scale loans repaid within a year, ideal for businesses needing fast access to funds.'
            },
            {
                heading: 'How do they differ from traditional loans?',
                text: 'Short term loans offer quicker approval, shorter repayment periods, and may be more accessible for businesses with varying credit profiles.'
            },
            {
                heading: 'Common uses?',
                text: 'Covering operational expenses, managing cash flow gaps, purchasing inventory, or seizing immediate business opportunities.'
            },
            {
                heading: 'Advantages?',
                text: 'Fast approval, flexible use of funds, and suitability for businesses with evolving financial needs.'
            },
            {
                heading: 'Qualification criteria?',
                text: 'Demonstrated revenue history, credit score, and basic financial documentation typically required.'
            },
        ]
    };

    function scrollToSection(sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <Navbar2 />
            {
                pageData ?
                    <div className="product-page">
                        <div className="banner-product">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7 col-sm-12 d-flex">
                                        <div className="texts">
                                            <h3>{pageData?.banner?.bannerHeading}</h3>
                                            <h6>{pageData?.banner?.bannerData}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <img src={pageData?.banner?.bannerImage} alt={pageData?.banner?.bannerImage} width={'100%'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="key-feature-section">
                            <div className="container">
                                <div className="headings">
                                    <h4 className='text-center'>Key Features</h4>
                                </div>
                                <div className="row">
                                    {pageData?.KeyFeatures?.map((value, index) => {
                                        return (
                                            <div className="col-lg-4 col-md-6 col-xs-12" key={index}>
                                                <div className="feature-box d-flex" style={{ alignItems: 'baseline' }} id={pageType}>

                                                    <img src={tickicon3} alt="tickIcon" style={{ width: '10%' }} className="me-2" />
                                                    <h6>{value}</h6>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="whatweoffer" style={{ marginTop: '100px' }}>
                            <Caraousel data={pageData?.whatWeOffer} />
                        </div>

                        <div className="Loanprocess-section-new">
                            <div className="container">
                                <div className="headings">
                                    <h4>Loan Process</h4>
                                </div>

                                <div className="row">
                                    {
                                        pageData?.LoanProcessNew?.map((value, index) => {
                                            return (
                                                <div className="col-lg-4 col-md-6 col-xs-12 mt-4" key={index}>
                                                    <div className="boxes" id={pageType == 'shortTermLoans' ? 'loanProcessshort':'loanProcessInvoice'}>
                                                        <div className="circle mb-3">
                                                            <h3>{index + 1}</h3>
                                                        </div>
                                                        <h4 className='mb-3 mt-4'>{value.heading}</h4>
                                                        <h6>{value.text}</h6>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>

                                <div className="headings text-center mt-5">
                                    <h3 className='questiontext mb-4'>Still have Questions?</h3>
                                    <Link className="btn-link" href="#" onClick={() => scrollToSection('contact')}>Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className="Loanprocess-section">
                            <div className="container">
                                <div className="headings">
                                    <h4>Loan Process</h4>
                                </div>
                                <div className="row mt-5">
                                    {
                                        pageData?.LoanProcess?.map((value, index) => {
                                            return (
                                                <div className={`mt-3 ${pageData?.LoanProcess?.length === 3 ? "col-lg-4 col-md-6 col-xs-12" : 'col-lg-3 col-md-6 col-xs-12'}`} key={index}>
                                                    <div className="Loan-sections">
                                                        <div className="top-section">
                                                            <img src={value?.Image} alt={value?.Image} width="41" />
                                                            <h3>{'0' + (index + 1)}</h3>
                                                        </div>
                                                        <h5 className='mt-2'>{value.text}</h5>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> */}

                        <div className="FAQ mb-5" style={{ marginTop: '50px' }}>
                            <div className="container">
                                <div className="block-left">
                                    <h4 className='text-center' style={{ fontSize: '30px', color: '#3B3B3B' }}>Frequently Asked Questions</h4>
                                </div>


                                <div className="row mt-4">
                                    <div className="col">
                                        <div class="accordion faqaccordion" id="accordionExample">
                                            {
                                                pageData?.FAQ?.map((value, index) => {
                                                    return (
                                                        <div class="accordion-item mt-3" key={index}>
                                                            <h2 class="accordion-header">
                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`} >
                                                                    {value.heading}
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse${index}`} class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">
                                                                    {value.text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    : ''}

            <Footer2 />
        </>
    )
}
